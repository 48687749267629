// Import our CSS
import '../less/main.less';

import $ from "jquery";
import jQuery from 'jquery'
window.jQuery = jQuery
import "./components/cookiebot";

import Prism from "prismjs";
import CustomNavigation from "./components/customNavigation";

$(function () {
    CustomNavigation.init();
    Prism.highlightAll();
});

// Add JS Loaded class to the body
document.documentElement.classList.add('js-loaded');

// App main
const main = async () => {
    // Import Scripts
    await import('./_scripts');

    await import("prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard");
    await import("./components/clearSessionStorage");
    await import('./scripts/calendly');
    await import("./components/cookiebot");
    await import("./components/fix_nav_scrolling");
    await import("./components/scroll_to_top");
    await import("./components/lazyloading");
    await import("./components/utm_forwarder");

    // Import Components
    //await import('./_vue-components');
    if (document.getElementById("app_vorteilsrechner")) {
        await import('../vue/main.js');
    }

    if (document.getElementById("signup-page")) {
        await import("./components/coupon");
        await import("./components/signup");
    }
};

// Execute async function
// eslint-disable-next-line
main()
// eslint-disable-next-line @typescript-eslint/no-empty-function
    .then(() => {

    })
    .catch((e) => console.error(e));
