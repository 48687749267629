window.addEventListener('CookiebotOnAccept', function () {
    if (Cookiebot.consent.marketing) {
        (function (opts) {
            var current = {
                    state: document.location.href,
                    query: document.location.search.substring(1),
                    time: new Date().getTime()
                },
                storage = {
                    area: window.sessionStorage,
                    key: 'last_utm_query'
                },
                restored,
                updated = {};

            if (/(^|&)utm_/.test(current.query)) {
                storage.area[storage.key] = JSON.stringify({ time: current.time, query: current.query });
            } else if (restored = JSON.parse(storage.area[storage.key] || '""')) {
                if (current.time - restored.time <= opts.expireDays * 864E5) {
                    updated.state = document.createElement('a');
                    updated.state.href = current.state;
                    updated.state.search += (updated.state.search ? '&' : '') + restored.query + '&restored=' + restored.time;
                    // history.replaceState('', {}, updated.state);
                }
            }
        })({ expireDays: 30 });
    }
}, false);

if(window.location.pathname.includes("signup-ios")) {
    Cookiebot.consented = true;
}
