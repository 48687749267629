import $ from "jquery";

export default {
    defaultTitle: "Home",
    depth: 0,
    duration: 3000,
    dom: {
        toggle: $("#toggle-nav"),
        overlay: $("#nav-overlay"),
        container: $("#drawer-container"),
        drawer: $("#drawer-content"),
        close: $(".hide-overlay"),
        prev: $("#prev"),
        title: $("#title"),
    },
    history: [],

    init: function() {
        this.initDom();
        this.initEvents();
        this.checkDepth();
    },
    checkDepth: function() {
        if(this.depth === 0) {
            $("#prev").hide(); 
        }else {
            $("#prev").show(); 
        }
    },
    initDom: function() {
        this.parent = this.dom.drawer.find("menu").first();
        this.dom.title.html(this.defaultTitle);
    },

    initEvents: function() {
        this.dom.container.on("click", () => {
            return false;
        });
        this.dom.toggle.on("click", this.slideDrawer.bind(this, true));
        /* this.dom.close.click(this.slideDrawer.bind(this, false)); */

        this.dom.drawer.find("a").on("click",
            function(e) {
                let target = $(e.target);

                if (target.hasClass("parent")) {
                    this.slideMenu(target.closest("li"));
                    this.checkDepth();
                } else {
                    window.location = target.attr("href");
                }
            }.bind(this)
        );

        this.dom.prev.on("click",
            function() {
                if (this.depth) {
                    this.slideMenu(null);
                    this.checkDepth();
                }
            }.bind(this)
        );
    },

    hasChildren: function(target) {
        return target.hasClass("parent");
    },

    slideDrawer: function() {
        this.dom.overlay.slideToggle("slide");
    },

    slideMenu: function(target) {
        let title = this.defaultTitle;
        if (target) {
            this.depth++;
            this.history.push(target);
            target.siblings().hide();
            target.show();

            title = target.find("> a:first-child").html();
        } else {
            this.depth--;
            target = this.history.pop();
            target.siblings().show();

            if (this.depth) {
                title = target
                    .closest("menu")
                    .closest("li")
                    .find("> a:first-child")
                    .html();
            }
        }

        this.parent.css({
            left: -this.depth * 100 + "%",
        });
        this.dom.title.html(title);
        this.dom.prev.toggleClass("visible", !!this.depth);
    },
};
